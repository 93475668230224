import { ScoringDocumentDto } from './scoring-document.dto';
import { ScoringPassportDto } from './scoring-passport.dto';

export enum ScoringStatusEnum {
  New = 'new',
  Processing = 'processing',
  Retry = 'retry',
  Done = 'done',
  Error = 'error',
}

export interface ScoringDto {
  id: string;
  userId: string;
  status: ScoringStatusEnum;
  errorMessage: string | null;
  documents: ScoringDocumentDto[];
  passport: ScoringPassportDto | null;
  inn: string | null;
  createdAt: Date;
  updatedAt: Date;
}
