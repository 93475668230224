import { ScoringDto } from './scoring.dto';
import { ScoringDocumentDto } from './scoring-document.dto';
import { ScoringPassportDto } from './scoring-passport.dto';

export type ScoringCreateDocumentDto = Omit<ScoringDocumentDto, 'id' | 'scoringId' | 'userId' | 'createdAt'>;

export type ScoringCreatePassportDto = Partial<
  Omit<ScoringPassportDto, 'userId' | 'scoringId' | 'createdAt' | 'updatedAt'>
>;

export interface ScoringCreateRequestDto extends Partial<Pick<ScoringDto, 'inn'>> {
  documents?: ScoringCreateDocumentDto[];
  passport?: ScoringCreatePassportDto | null;
}
